import React from 'react';
import classnames from "classnames";

import rightArrow from '../../assets/icons/right-arrow.svg';
import bottomArrow from '../../assets/icons/bottom-arrow.svg';

import styles from "./styles.module.scss";

const ServiceBox = ({ serviceDetails, setCoverImage, indexNumber, toggleServiceSelected }) => {

    const onSelectCoverImage = (coverImage) => {
        toggleServiceSelected(indexNumber);
        setCoverImage(coverImage);
    }
    return (
        <div
            className={styles.item}
            onClick={() =>
                onSelectCoverImage(serviceDetails.selectableImage)
            }>
            <div className={styles.itemInfo}>
                {serviceDetails.icon ? <img src={serviceDetails.icon} alt="icon" width={40} height="auto" /> : ''}
                <span>{indexNumber === 0 ? serviceDetails.label : serviceDetails.label}</span>
                {serviceDetails.selectable &&
                    <>
                        <img src={rightArrow} className={styles.leftArrow} alt="More content desktop" width={8} height="auto" />
                        <img src={bottomArrow} className={classnames(styles.bottomArrow, {
                            [styles.active]: serviceDetails.selectable && serviceDetails.selected
                        })} alt="More content mobile" width={8} height="auto" />
                    </>
                }

            </div>
            {serviceDetails.selectable && serviceDetails.selected && <img src={serviceDetails.mobileSelected} className={styles.coverImg} alt="Cover Image" width={193} height="auto" />}
        </div>
    )
}

export default ServiceBox;
