import React, { useState } from 'react';

import {
  trainingSteps,
  transpernecySteps,
  bespokeSteps
} from "../../constants/verticalValues";
import { servicesData } from "../../constants/services";

import FooterComponent from "../Footer";
import ServiceBox from "../ServiceBox";

import HeroLogoSVG from '../../assets/hero-logo.png';
import HeroImage2 from '../../assets/hero-image-2.png';
import TitleDots from "../../assets/icons/title-dot.svg";
import HeroImage3 from '../../assets/hero-image-3.png';
import desktopCheck from '../../assets/step-check-desktop.png';
import mobileCheck from '../../assets/step-check-mobile.png';
import MasterOfOne from '../../assets/master-one.png';
import cover2 from '../../assets/coverimage2.png';
import SecurityPersonal from '../../assets/security-personal.png';

import './VerticalTabs.scss';

export const VerticalTabs = () => {
  const [image, setImage] = useState(SecurityPersonal);
  const [services, setServices] = useState(servicesData);

  const toggleServiceSelected = (indexNumber) => {
    const toggledServices = services.map((item, i) => {
      if (indexNumber === i) {
        return { ...item, selected: !item.selected };
      }
      return { ...item, selected: false };
    });

    setServices(toggledServices);
  }

  return (
    <div className='panel-wrapper' data-bs-spy="scroll" data-bs-target="#list-menu" data-bs-smooth-scroll="true">

      <section id="section-1" className="tab-panel">
        <div className='main-section'>
          <div className='logo-container col-5 d-flex flex-column align-items-center'>
            <div>
              <img src={HeroLogoSVG} alt="logo" width="auto" height="auto" />
              <h4 className="logo-title">Leaders in Loss Prevention</h4>
            </div>
          </div>
          <div className='cover-container col-7 h-100'>
            <div className='cover-image-wrapper d-flex h-100'>
              <div className='image-caption'><p>Ontario’s Premium Retail Asset Protection Specialists</p></div>
              <img className="cover-image align-self-end" src={cover2} alt="" width={309} height={550} />
            </div>
          </div>
        </div>
      </section>

      <section id="section-2" className="tab-panel">
        <div className='main-section'>
          <div className="contents-row">
            <div className='section-info light-grey-color'>
              <div className="left-overlay"></div>
              <div>
                <h4 className="section-titles">
                  <img src={TitleDots} alt="dot" width={10} height={10} />
                  What We Do
                </h4>
                <p className='description'>We reduce shrink in your store by curating the perfect balance between tactical uniform presence and plainclothes floorwalkers.</p>
              </div>
            </div>
            <div className='section-image'>
              <img src={HeroImage2} alt="logo" width={459} height={468} />
            </div>
          </div>
        </div>
      </section>

      <section id="section-3" className="tab-panel">
        <div className="main-section d-flex">
          <img src={HeroImage3} alt="logo" className='cover-image' width={480} height={522} />
          <div className='section-info dark-grey-color'>
            <h4 className="section-titles"><img src={TitleDots} alt="dot" width={10} height={10} />Our People</h4>
            <p className='description'>We exclusively only work with guards who have military or police aspirations. As such, they treat their time with us as a stepping stone in their security-related careers and not just a side gig while in school or as they await the job they really want.</p>
          </div>
        </div>
      </section>

      <section id="section-4" className="tab-panel">
        <div className="main-section">
          <div className='section-info'>
            <h3 className="section-titles"><img src={TitleDots} alt="dot" width={10} height={10} />Services</h3>
            <div className="multi-buttons-wrapper">
              {services && services.map((item, i) => (
                <ServiceBox
                  key={i}
                  serviceDetails={item}
                  setCoverImage={setImage}
                  toggleServiceSelected={toggleServiceSelected}
                  indexNumber={i}
                />
              ))}
            </div>
          </div>
          <img src={image} className='cover-image' alt="security personel" width={597} height="auto" />
        </div>
      </section>

      <section id="section-5" className="tab-panel">
        <div className='main-section'>
          <div className='section-info'>
            <div className='left-overlay'></div>
            <h3 className="section-titles"><img src={TitleDots} alt="dot" width={10} height={10} />Master of One</h3>
            <p>Other companies treat security like a buffet, doing a few condos, a little bit of retail, some construction and event security, and a hint of mobile patrol -- anything and everything they can get their hands on.
            </p>
            <p>Though they boast their jack of all trade-ness, their lack of focus never satisfies any client in any sector, ever.
            </p>
            <p>
              Steph Curry didn&apos;t try to be the greatest rebounder, passer, dribbler and dunker at the same time. He did just one thing, and one thing better than every body else.
            </p>
            <p>Our training, hiring, compensation, incentives, technology stack, culture and conversations all revolve around one endeavour: how to radically support the store owner and reduce shoplifting. And since we dont need to waste time worrying about other sectors of security, we can dominate the Loss Prevention niche.</p>
            <p>
              What shooting is to Steph, retail asset protection is to Lion Guard.
            </p>
            <strong>We are specialists.</strong>
          </div>
          <img src={MasterOfOne} className='cover-img' alt="" width="auto" height="auto" />
        </div>
      </section>

      <section id="section-6" className="tab-panel">
        <div className='main-section'>
          <h3 className="section-titles"><img src={TitleDots} alt="dot" width={10} height={10} />Bespoke And Boutique</h3>
          <p className='description'>We tailor our service and personnel to these store-specific factors:</p>

          <div className='contents-row'>
            {bespokeSteps.map((f) => (
              <div className='item' key={f.logo}>
                <div className='item-cover d-flex align-items-center justify-content-center'>
                  <img src={f.logo} alt="store" width="auto" height="auto" />
                </div>
                <div className='item-info d-flex flex-column align-items-center justify-content-center'>
                  <p>{f.labelA}<br />{f.labelB}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>

      <section id="section-7" className="tab-panel">
        <div className='main-section'>
          <div className='title-section d-flex justify-content-center align-items-center'>
            <h3 className="section-titles"><img src={TitleDots} alt="dot" width={10} height={10} />Training Fundamentals</h3>
          </div>

          <div className='section-info'>
            <h3 className='text-blue-highlight'>5 Steps</h3>
            {trainingSteps.map((step, i) => (
              <div className='step-item d-flex align-items-center' key={step.label}>
                <strong>0{i + 1}</strong>
                <div>
                  <h4>{step.label}</h4>
                  <p className="text-secondary-grey">{step.value}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>

      <section id="section-8" className="tab-panel">
        <div className='main-section'>
          <h3 className="section-titles"><img src={TitleDots} alt="dot" width={10} height={10} />Transparency and Communication</h3>
          <div className='item-row d-flex align-items-start'>
            <img src={mobileCheck} alt="store" className='steps-check-mobile' width="auto" height="auto" />
            <img src={desktopCheck} alt="store" className='steps-check-desktop' width="auto" height="auto" />
            {transpernecySteps.map((f) => (
              <div className='item' key={f.label}>
                <div className="item-box">
                  <p>{f.label}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>

      <section id="section-9" className='tab-panel'>
        <div className='main-section'>
          <div className="left-overlay"></div>
          <h3 className="section-titles"><img src={TitleDots} alt="dot" width={10} height={10} />Get In Touch</h3>
          <p className='description'>We are here to help. Drop as a message and we’ll get back at once.</p>

          <form className='d-flex flex-column'>
            <div className="fields-row row">
              <div className="col">
                <label htmlFor='firstName'>FIRST NAME</label>
                <input type="text" name='firstName' className="form-control" placeholder="Enter your first name here" />
              </div>
              <div className="col">
                <label htmlFor='lastName'>LAST NAME</label>
                <input type="text" name='lastName' className="form-control" placeholder="Enter your last name here" />
              </div>
            </div>

            <div className="fields-row row">
              <div className="col">
                <label htmlFor='email'>EMAIL</label>
                <input type="email" name='email' className="form-control" placeholder="Enter your email" />
              </div>
              <div className="col">
                <label htmlFor='phoneNumber'>PHONE NUMBER</label>
                <input type="text" name='phoneNumber' className="form-control" placeholder="Enter your phone number" />
              </div>
            </div>

            <div>
              <label>MESSAGE</label>
              <textarea className="form-control" name='message' placeholder='Enter your message here'></textarea>
            </div>
            <button type='button' className='submit-button'>Submit</button>
          </form>
        </div>
      </section>
      <FooterComponent />
    </div >
  );
}

export default VerticalTabs;