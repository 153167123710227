import React from "react";
import { createTheme, ThemeProvider } from "@mui/material";
import bootstrap from 'bootstrap';

import VerticalTabs from "../../components/VerticalTabs/VerticalTabs";
import SideNavbar from "../../components/SideNavbar";

import './Home.css';


export const Home = () => {
  // const [value, setValue] = useState(0);
  const darkTheme = createTheme({
    palette: {
      mode: 'dark',
      primary: {
        main: '#1976d2',
      },
    },
  });
  // function a11yProps(index) {
  //   return {
  //     id: `vertical-tab-${index}`,
  //     "aria-controls": `vertical-tabpanel-${index}`
  //   };
  // }


  return (
    <div className="home">
      <ThemeProvider theme={darkTheme}>
        <div className="vertical-tabs" id="containerElement">

          <SideNavbar />
          <VerticalTabs />
        </div >
      </ThemeProvider>
    </div>
  )
}

export default Home;