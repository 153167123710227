import React from "react";
import classnames from "classnames";

import logoImage from '../../assets/logo.png';
import linkedin from '../../assets/icons/footer-linkedin.svg';
import instagram from '../../assets/icons/footer-instagram.svg';

import styles from "./styles.module.scss";

const FooterComponent = () => {
    return (
        <div className={
            classnames("w-100 text-white d-flex justify-content-between align-items-center", 
                [styles.footerContainer]
            )}
        >
            <p className="mb-0 fs-6">© 2022 Lion Guard, All Rights Reserved.</p>
            <div className="d-flex align-items-center">
                <img src={logoImage} alt="Logo Image" className={classnames("mx-3", [styles.logoImage])} width={40} height={51} />
                <img src={linkedin} alt="Logo Image" className="mx-3" width={22} height={22} />
                <img src={instagram} alt="Logo Image" className="mx-3" width={22} height={22} />
            </div>
            <p className="mb-0 fs-6">Privacy Policy | Terms & Conditions</p>
        </div>
    )
}

export default FooterComponent;
