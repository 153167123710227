import PlainClothesIcon from '../assets/icons/plain-clothes-icon.svg';
import RecoveryIcon from '../assets/icons/recovery-icon.svg';
import HandsArestsIcon from '../assets/icons/hands-arests-icon.svg';
import TacticalIcon from '../assets/icons/tactical-icon.svg';
import TrespassIcon from '../assets/icons/trespass-icon.svg';
import CctvIcon from '../assets/icons/cctv-icon.svg';
import VisualIcon from '../assets/icons/visual-icon.svg';
import SecurityPersonal from '../assets/security-personal.png';
import SecurityPersonal2 from '../assets/security-personal-2.png';
import MobileSecurityPersonal from '../assets/security-personal-mobile.png';
import MobileSecurityPersonal2 from '../assets/security-personal-mobile-2.png';

export const servicesData = [
    {
        icon: PlainClothesIcon,
        label: 'PLainclothes Floorwalkers',
        selectable: true,
        selected: false,
        selectableImage: SecurityPersonal,
        mobileSelected: MobileSecurityPersonal,
    },
    {
        icon: TrespassIcon,
        label: 'TRESPASS NOTICE',
        selectable: false,
        selected: false,
        selectableImage: ''
    },
    {
        icon: TacticalIcon,
        label: 'TACTICAL Uniform ',
        selectable: true,
        selected: false,
        selectableImage: SecurityPersonal2,
        mobileSelected: MobileSecurityPersonal2,
    },
    {
        icon: CctvIcon,
        label: 'CCTV Monitoring',
        selectable: false,
        selected: false,
        selectableImage: ''
    },
    {
        icon: HandsArestsIcon,
        label: 'Hands on Vs. Hands Off Arrests',
        selectable: false,
        selected: false,
        selectableImage: ''
    },
    {
        icon: VisualIcon,
        label: 'Visual deterrance',
        selectable: false,
        selected: false,
        selectableImage: ''
    },
    {
        icon: RecoveryIcon,
        label: 'CIVIL RECOVERY',
        selectable: false,
        selected: false,
        selectableImage: ''
    }
];