import React, { useState, useRef } from "react";
import FooterComponent from "../Footer";
import logoImage from "../../assets/logo.png";
import "./styles.scss";

const SideNavbar = () => {
    const [isMobileOpen, setIsMobileOpen] = useState(false);
    const hiddenCheckboxRef = useRef(null);

    const onMobileOpenChange = (isChecked) => {
        if (isChecked) setIsMobileOpen(true);
        else setIsMobileOpen(false);
    }

    const closeMobileMenu = () => {
        hiddenCheckboxRef.current.checked = false;
        setIsMobileOpen(false);
    }
    return (
        <div className="side-nav-menu">
            <img src={logoImage} alt="Logo Image" className="logo-img" width={57} height={74} />
            <div id="menuToggle">
                <input ref={hiddenCheckboxRef} type="checkbox" onChange={(e) => onMobileOpenChange(e.target.checked)} />
                <span></span>
                <span></span>
                <span></span>
            </div>
            <div className="nav-main-wrapper" style={isMobileOpen ? { transform: "translate(0, 0)" } : {}}>
                <img src={logoImage} alt="Logo Image" className="mobile-logo-img" width={52} height={66} />
                <div className="list-group align-self-center" id="list-menu" onClick={closeMobileMenu}>
                    <a className="list-group-item list-group-item-action" href="#section-1">Home</a>
                    <a className="list-group-item list-group-item-action" href="#section-2">What We Do</a>
                    <a className="list-group-item list-group-item-action" href="#section-3">Our People</a>
                    <a className="list-group-item list-group-item-action" href="#section-4">Services</a>
                    <a className="list-group-item list-group-item-action" href="#section-5">Master Of One</a>
                    <a className="list-group-item list-group-item-action" href="#section-6">Bespoke and Boutique</a>
                    <a className="list-group-item list-group-item-action" href="#section-7">Training</a>
                    <a className="list-group-item list-group-item-action" href="#section-8">Transparency</a>
                    <a className="list-group-item list-group-item-action" href="#section-9">Contact Us</a>
                </div>
                <div className="mobile-menu-footer">
                    <FooterComponent />
                </div>
            </div>

        </div>
    )
}

export default SideNavbar;
